import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "How can you be more disciplined in your daily goals",
  "author": "Patrick Passarella",
  "date": "2020-08-12",
  "subtitle": "A better way to be focused and grow yourself",
  "cover": "./cover.jpg",
  "coverCredit": "Thao Le Hoang",
  "coverWebsite": "Unsplash",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Often I find myself planning my days, but shortly after forgetting about it. This happens because after doing something that makes us happy, our brain likes it, and doesn't want to do other things. It's great to do what makes us happy, and that's also necessary, but what about other required needs, like studying and reading (or anything really)? Everything is important.`}</p>
    <p>{`Almost no one can add healthy habits to their days easily. If you have unhealthy habits, you need to improve yourself first. If you procrastinate all day, you will not be able to be fully disciplined on your daily schedule right away. that's a goal for a different version of yourself, where you have fixed some unhealthy habits first. Then it will be much easier and natural. You have probably noticed that healthy people have a healthier life. It's hard to be fit and also eat bad food, but if you change your diet, you can start being fit. It's like a chain of healthy habits, start one, get better at it, and you will want to start another.`}</p>
    <blockquote>
      <p parentName="blockquote">{`It's easier to fix bad habits first than adding new ones.`}</p>
    </blockquote>
    <p>{`Set small goals first, start to get used to it. For example, regardless of the day, I try to read at least 15 minutes, and always go to sleep before 12 am. After some time it was natural for me, and it will probably be natural for you.`}</p>
    <p>{`Some other daily goals that I try to accomplish:`}</p>
    <ul>
      <li parentName="ul">{`Wake up early in weekdays`}</li>
      <li parentName="ul">{`Work out everyday`}</li>
      <li parentName="ul">{`Leave work early`}</li>
      <li parentName="ul">{`Have at least one "free" hour every day (usually 1 hour before I go to sleep)`}</li>
    </ul>
    <p>{`I value time a lot, so most of my goals have time involved in it, to use the most of my day and life.`}</p>
    <h2>{`Having goals and making a to-be list`}</h2>
    <p>{`It's easy to say that a lot of people have trouble to accomplish their daily tasks. Most of the time it is because they are creating a to-do list and trying to remember to do all the tasks. But just doing a to-do list usually doesn't work too well. Try making a to-be list instead, where you note who you want to become. In every item in this list create a to-do list with things that you can do to achieve it.`}</p>
    <p>{`I know it sounds weird and overly complicated, but there is more than one way of doing it, and it helps a lot in the long term. Some people like to note what are their daily goals. I prefer to note who I want to be in general, which is better for more long-term tasks. The main point is to try it, write down what's on your mind, doesn't matter if it is for that day or forever.`}</p>
    <p>{`For example, if one item in your to-be list is to be healthier, you can have a set of tasks for it, like eat fewer carbs at breakfast. Or if another item is to be a better developer in a short period, you could add a task to study 2 to 4 hours a day or to work on your side-project. That's on you, and I encourage you to stop for some minutes to think about it.`}</p>
    <p>{`Another important point is to really write it down. Make a list of what you want to achieve each day, your to-be, and to-do list. Have reminders. What works for me is setting an alarm clock for an hour that I need to do something, like wake-up, stop working, go to sleep, study. But do what works for you and your desired lifestyle.`}</p>
    <p>{`If you are having a hard time, try to force yourself a little more in the beginning. Even if your brain is telling you to continue awake, stop, think for a second, and force yourself to go to sleep (for example). But remember, don't be too harsh on yourself, it takes time and effort, consistency is key. A very good trick that works for me, is to always start with the productive tasks first, start with what you need to do, then what you want to do. That way you will naturally do it, and maybe you'll enjoy it and work for a little more, which is great. If you are having motivation issues, you could first do the tasks that are not that important, and are easier, but needs to be done. Because since you're already without much motivation, try to do it already and get rid of it. Sometimes doing that way will at least make you take action, kinda like an initial push.`}</p>
    <p>{`Don't try to change everything at once, you'll get overwhelmed, do one thing at a time and you will become more disciplined and focused.`}</p>
    <p>{`Another great thing to do is to clean your workspace, have your desk organized. If you can (you should), clean your place too, it will make a lot of difference in your concentration skills.`}</p>
    <p>{`I hope it helped someone! it's not something set in stone, everyone has a different way to do something, it could or not work for you. Try different things. Remember to always have a set of goals, that way, every action on your life will be towards that goal.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      